import React from "react";
import { Col, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
const ModalCustom = ({
  period,
  day,
  time,
  countday,
  dailyPrice,
  monthlyPrice,
}) => {
  return (
    <div>
      <Row>
        <Col xs={12} md={12}>
          {/* Information to display in the modal */}
          <h2>Daily fee</h2>
          <h3>{`£${dailyPrice} / ${day} session , ${time}`}</h3>
          <h2>{period}</h2>
          <h3>{`£${monthlyPrice} / ${countday}  ${day} session , ${time}`}</h3>
          <a href="https://chesshood.com">chesshood.com</a>
        </Col>
        <Col xs={12} md={12}></Col>
        <Image
          src="qr_9AQ28z9vBe254Du3ch.png"
          alt="Descriptive text"
          className="homclubimg"
        />
      </Row>
      <Row>
        <Col>
          <img
            src="/adamcamp.webp"
            alt="Descriptive text"
            className="homclubimg"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ModalCustom;
