import React, { useState } from "react";
import ScheduleTable from "./ScheduleTable";
import { Row, Col, Button, Modal } from "react-bootstrap";
import ModalCustom from "./ModalCustom";

const JuniorClubWinchester = () => {
  // State to manage modal visibility
  const [showModal, setShowModal] = useState(false);

  // Function to handle button click and open the modal
  const handleButtonClick = () => {
    setShowModal(true);
  };

  // Function to handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="juniorchess">
      <h1 className="textheader">Winchester Junior Chess Club</h1>

      <Row>
        <Col xs={12} md={6}>
          <h2>Chess Mastery Awaits</h2>
          <p className="motivational-quote">
            The beautiful thing about learning is that nobody can take it away
            from you. Dive into the world of chess and unlock the grandmaster
            within you. Every move on the board teaches you something new,
            presenting challenges that sharpen your mind and spirit. Remember,
            every great chess player was once a beginner. Embrace patience,
            strategy, and foresight. Let your journey to mastery be a testament
            to your dedication and passion for chess. Your potential is
            unlimited, and every game is a new opportunity to surpass your
            previous limits. Stand ready at the board, for it's not just a game,
            but a gateway to discovering your true prowess. Take the next step,
            make your move, and let's embark on this adventure together. Your
            chess story is just beginning, and the path to greatness is yours to
            claim.
          </p>
          <p>
            {/* Button to open the modal */}
            <Button variant="primary" onClick={handleButtonClick}>
              Buy Ticket for Chess Club
            </Button>
          </p>
        </Col>
        <Col xs={12} md={6}>
          <img
            src="/chesscamp.webp"
            alt="Descriptive text"
            className="homclubimg"
          />
        </Col>
      </Row>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className="modalTitle">
            <h1>Chess club Fee</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <ModalCustom
            period={"Monthly"}
            day="Sunday"
            time="11am - 1pm"
            countday={4}
            dailyPrice={10}
            monthlyPrice={32}
          />
        </Modal.Body>
        <Modal.Footer>
          {/* Close button */}
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ScheduleTable
        dates={["1/9/24", "8/9/24", "15/9/24", "29/9/24"]}
        hours="11am - 1pm"
      />
    </div>
  );
};

export default JuniorClubWinchester;
