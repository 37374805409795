import React, { useState } from "react";
import { Col, Row, Button, Modal } from "react-bootstrap";

import ScheduleTable from "./ScheduleTable";
import ModalCustom from "./ModalCustom";

const Chesscamp = () => {
  // State to manage modal visibility
  const [showModal, setShowModal] = useState(false);

  // Function to handle button click and open the modal
  const handleButtonClick = () => {
    setShowModal(true);
  };

  // Function to handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <Row>
        <Col xs={12} md={6}>
          <h2>Holiday Chess Camp: Strategic Play and Seasonal Fun</h2>

          <p>
            Embrace the holiday season with strategic gameplay at our Holiday
            Chess Camp, scheduled for the upcoming break. This edition of our
            chess camp combines the festive spirit with the intellectual
            challenge of chess, providing a unique opportunity for learning and
            competitive play.
          </p>

          <p>
            Daily sessions will run from 10 am to 2 pm. Participants are
            encouraged to bring their own lunch, adding to the festive
            atmosphere. Our no food-sharing policy ensures the safety and
            well-being of all attendees.
          </p>

          <p>
            The Holiday Chess Camp goes beyond improving chess skills, it's a
            chance to develop vital life skills such as critical thinking,
            patience, and problem-solving. Participants will explore the history
            of chess, analyze classic games, and learn tactics from renowned
            chess masters. The camp also offers insights into current chess
            tournaments and scenes.
          </p>

          <p>
            An early bird registration fee of £30 per day is available for those
            who register before a specific date, providing an incentive for
            early participation. The full camp experience is priced at £130,
            with registrations after the early bird period at £30 per day. The
            camp promises a wealth of knowledge, strategic development, and
            lasting friendships.
          </p>

          <p>
            In addition to chess, campers will have hourly breaks for physical
            activities, offering a balanced mix of mental and physical
            engagement. Whether a beginner or an advanced player, our Holiday
            Chess Camp is designed to enhance your abilities in a friendly and
            inclusive setting.
          </p>

          <p>
            Join us for the Holiday Chess Camp, where each move celebrates the
            season, and every strategy brings the joy of the holidays to life.
            Book your spot now for an unforgettable chess experience during the
            upcoming break.
            {/* Button to open the modal */}
            <Button variant="primary" onClick={handleButtonClick}>
              Buy Ticket for Chess Camp (Pay As You Go)
            </Button>
          </p>
        </Col>
        <Col xs={12} md={6}>
          <img
            src="/adamcamp.webp"
            alt="Spring Chess Camp"
            className="londoncampjuniorimg"
          />
          <img
            src="/emptyroom.jpg"
            alt="Descriptive text"
            className="homclubimg"
          />
        </Col>
      </Row>
      <ScheduleTable
        dates={["Ask for the next camp dates"]}
        hours="10am - 2pm"
      />
      {/* Modal */}
      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className="modalTitle">
            Chess Camp Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <ModalCustom
            period={"Spring Half Term"}
            day="Monday to Friday"
            time="10am - 2pm"
            countday={5}
            dailyPrice={30}
            monthlyPrice={130}
          />
        </Modal.Body>
        <Modal.Footer>
          {/* Close button */}
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default Chesscamp;
