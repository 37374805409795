import React from "react";

const ScheduleTable = ({ dates, hours }) => (
  <table className="table">
    <thead>
      <tr>
        <th>Date</th>
        <th>Hours</th>
      </tr>
    </thead>
    <tbody>
      {dates.map((date, index) => (
        <tr key={index} className={`row-color-${index % 3}`}>
          {/* The % 3 will cycle through 3 colors */}
          <td key={index} className={`row-color-${index % 3}`}>
            {date}
          </td>
          <td key={index} className={`row-color-${index % 3}`}>
            {hours}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default ScheduleTable;
