import React from "react";

const ChessActivitiesSouthamptonCentral = () => {
  return (
    <div className="event-details">
      <h1>Chess Activities on Saturdays</h1>

      <div className="event-metadata">
        <strong>Date and Time:</strong> Every Saturday, 1:00 PM - 2:30 PM
        <br />
        <strong>Location:</strong> 11 Portland Street, Southampton, SO14 7EB, UK
      </div>

      <h2>About This Event</h2>
      <p>
        Join us for weekly chess activities every Tuesday from 4:00 PM to 6:00
        PM. Whether you're a beginner learning the basics or an advanced player
        honing your strategies, these sessions offer an opportunity for players
        of all levels to improve their skills and enjoy the game of chess.
      </p>

      <h2>Schedule</h2>
      <ul>
        {[
          "1:00 PM - 1:30 PM: Warm-up and practice games",
          "1:30 PM - 2:00 PM: Lesson on chess tactics and strategies",
          "2:00 PM - 2:30 PM: Chess puzzles and problem-solving",
        ].map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>

      <h2>Location</h2>
      <p>
        The activities are held at 11 Portland Street, Southampton, SO14 7EB,
        UK. It's a great venue that provides a comfortable environment for
        learning and playing chess.
      </p>

      <h2>Contact</h2>
      <p>
        For more information, please contact us at{" "}
        <a href="mailto:chesshampshire@gmail.com">chesshampshire@gmail.com</a>.
      </p>
    </div>
  );
};

export default ChessActivitiesSouthamptonCentral;
