import React from "react";

import JuniorClubWinchester from "./JuniorClubWinchester";
import Chesscamp from "./Chesscamp";

import Spaciador from "../components/Spaciador";
import ChessTournamentInfo from "./ChessTournamentInfo";
import LichessTeamBattleInfo from "./LichessTeamBattleInfo";
import ChessActivitiesSouthamptonCentral from "./ChessActivitiesSouthamptonCentral"; // Import the new component
import HJCABlitz from "./HJCABlitz";
const PrivateLessons = () => (
  <div>
    <h2>Private 1 to 1 Lessons: Tailored Chess Instruction</h2>
    <p>
      Understanding that each chess player's journey is unique, we offer private
      1 to 1 lessons tailored to your individual learning needs. Whether you are
      a beginner grasping the basics, an intermediate player seeking to improve
      your tactics, or an advanced player aiming to perfect your strategies, our
      private lessons provide the targeted guidance that you need.
    </p>

    <p>
      These sessions offer a unique opportunity for personalized learning at
      your own pace. We'll dive deep into your games, dissecting each move to
      understand what went right and what can be improved. This thorough
      analysis allows you to gain a deeper insight into your playing style,
      strengths, and areas for improvement. The goal is not only to enhance your
      chess acumen but also to instill a strong understanding of the game's
      fundamentals that will stay with you throughout your chess journey.
    </p>

    <p>
      Further, the 1 to 1 lessons can be flexible in terms of scheduling,
      ensuring they fit comfortably into your routine. If you're interested in
      this personalized approach to chess mastery, please reach out to check
      availability and schedule your private lesson.
    </p>
  </div>
);

const JuniorChessClub = () => {
  return (
    <div>
      <JuniorClubWinchester />
      <Spaciador axis="vertical" size="100px" />
      <ChessActivitiesSouthamptonCentral />
      <Spaciador axis="vertical" size="100px" />
      <Chesscamp />
      <Spaciador axis="vertical" size="100px" />

      <ChessTournamentInfo />
      <Spaciador axis="vertical" size="100px" />
      <HJCABlitz />
      <Spaciador axis="vertical" size="100px" />
      <PrivateLessons />
      <Spaciador axis="vertical" size="100px" />
      <LichessTeamBattleInfo />
    </div>
  );
};

export default JuniorChessClub;
