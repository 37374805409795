import React from "react";

const HJCABlitz = () => {
  return (
    <div className="event-details">
      <h1>Hampshire Open Junior FIDE Blitz 2024</h1>
      <p>Organized by the Hampshire Junior Chess Association (HJCA)</p>
      <div className="event-metadata">
        <strong>Date and Time:</strong> Sunday, April 7th, 2024, 09:00 - 17:00
        GMT
        <br />
        <strong>Location:</strong> Badger Farm Community Centre, Winchester,
        SO22 4QB
        <br />
        <strong>Refund Policy:</strong> Refunds up to 7 days before the event.
        Eventbrite's fee is nonrefundable.
      </div>

      <h2>About This Event</h2>
      <p>
        The Hampshire Open Junior FIDE Blitz is for all players who are aged 18
        or fewer years on August 31st, 2024, regardless of location of residence
        and/or school. Join us for a day filled with competitive chess,
        strategic learning, and fun!
      </p>

      <h2>Event Details</h2>
      <p>
        There will be 15 rounds by Swiss pairings, Blitz time control, with a
        £215 prize fund.
      </p>
      <p>
        At the moment, the entry is limited to one hundred players, so be sure
        to enter early to avoid disappointment!
      </p>

      <h2>Registration</h2>
      <p>
        For full details and registration, visit{" "}
        <a
          href="https://www.eventbrite.co.uk/e/hampshire-open-junior-fide-blitz-2024-tickets-854423141617"
          target="_blank"
          rel="noopener noreferrer"
        >
          Eventbrite
        </a>
        .
      </p>
    </div>
  );
};

export default HJCABlitz;
