import { Navbar, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faChess } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function SideNav() {
  return (
    <div className="menu-left p-3">
      <Navbar.Brand href="/">
        <h4 className="text-center logo-text">
          Winchester Junior <br></br>Chess Club
        </h4>
        <br></br>
        <img
          src="logo.png"
          alt="logo"
          className="d-inline-block align-top logo logo-img"
        />
      </Navbar.Brand>

      <Navbar collapseOnSelect expand="sm" className="navbarhood">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="flex-column me-auto menu-after-logo">
            <Link to="/" className="nav-link">
              <FontAwesomeIcon icon={faHome} /> Home
            </Link>

            <Link to="/privatelessons" className="nav-link">
              <FontAwesomeIcon icon={faChess} /> Private Lessons
            </Link>

            <Link to="/JuniorClubWinchester" className="nav-link">
              <FontAwesomeIcon icon={faChess} /> Junior Chess Club
            </Link>

            {/* <Card className="p-1 m-2">
              <Card.Title className="text-center card-title-bg-color h6">
                <Link to="/ChessCamp" className="nav-link">
                  <FontAwesomeIcon icon={faChess} /> Chess Camp
                </Link>
              </Card.Title>
            </Card> */}
            {/* <Card className="p-1 m-2">
              <Card.Title className="text-center card-title-bg-color h6">
                <Link to="/ChessTournamentInfo" className="nav-link">
                  <FontAwesomeIcon icon={faChess} /> Chess Tournament
                </Link>
              </Card.Title>
            </Card> */}

            <Link to="/OnlineMeetings" className="nav-link">
              <FontAwesomeIcon icon={faChess} /> Online Meetings
            </Link>

            <Link to="/ContactPage" className="nav-link">
              <FontAwesomeIcon icon={faChess} /> Contact
            </Link>

            <a
              href="/HantsEntryForm2024.pdf"
              className="nav-link"
              rel="noopener noreferrer"
            >
              <b>
                HAMPSHIRE CHESS ASSOCIATION CONGRESS 2024 & Hampshire Individual
                Championships
              </b>
            </a>
            <a href="https://www.hjca.org.uk/" className="nav-link">
              {" "}
              <b>HAMPSHIRE JUNIOR CHESS ASSOCIATION</b>
            </a>
            <Link to="/HJCABlitz" className="nav-link">
              {" "}
              <b>HJCA Blitz</b>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default SideNav;
