import React from "react";

const ContactPage = () => {
  // Replace with the actual coordinates of Badger Farm Community Centre
  const latitude = "51.0463342731906";
  const longitude = "-1.3392393577250998";

  return (
    <div>
      <h1>Contact Us</h1>
      <p>If you have any questions, feel free to reach out to us:</p>
      <p>
        Email:{" "}
        <a href="mailto:Chesshampshire@gmail.com">Chesshampshire@gmail.com</a>
      </p>
      <p>
        Phone: <a href="tel:+447711804361">0044 7711804361</a>
      </p>
      <hr></hr>
      <h3>Badger Farm Community Centre</h3>
      <p>2 Badger Farm Road Winchester SO22 4QB</p>
      <div>
        <iframe
          title="Badger Farm Community Centre Map"
          src={`https://www.google.com/maps?q=${latitude},${longitude}&hl=es;z=14&output=embed`}
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactPage;
