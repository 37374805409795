import React from "react";

const ChessTournamentInfo = () => {
  return (
    <div className="event-details">
      <h1>Hampshire Junior Open Chess Congress 2024</h1>
      <p>Organized by the Hampshire Junior Chess Association</p>
      <div className="event-metadata">
        <strong>Date and Time:</strong> Sunday, 17 Nov 2024, 09:00 - 17:00 GMT
        <br />
        <strong>Location:</strong> Badger Farm Community Centre, 2 Badger Farm
        Road, Winchester, SO22 4QB
        <br />
        <strong>Refund Policy:</strong> Refunds up to 7 days before event.
        Eventbrite's fee is nonrefundable.
      </div>

      <h2>About This Event</h2>
      <p>
        The Hampshire Junior Open Tournament is for young chess players who are
        aged 18 years or less on August 31st, 2024, regardless of residence
        location and/or school. Join us for a day filled with competitive chess,
        strategic learning, and fun!
      </p>

      <h2>Schedule</h2>
      <ul>
        {[
          "09:30 Arrive",
          "09:45 Intro",
          "10:00 Round 1",
          "11:00 Round 2",
          "12:00 Round 3",
          "13:00 Lunch",
          "14:00 Round 4",
          "15:00 Round 5",
          "16:00 Round 6",
          "17:00 Prizegiving",
        ].map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>

      <h2>Prizes</h2>
      <p>
        Cash prizes will be awarded, with a total prize fund of £215. Prizes
        range from £5 to £80, including a £15 prize for the highest placed girl
        player.
      </p>

      <h2>Entry Fees and Rules</h2>
      <ul>
        <li>Entry fee for all age categories: £30</li>
        <li>
          Late entry fee for entries received after Friday 15th November 2024:
          £10.00
        </li>
        <li>
          Entrants must either be a Silver or higher ECF member or pay a
          "Pay-to-Play" fee
        </li>
        <li>
          Bronze ECF members must pay a fee of £7.50 to upgrade from Bronze to
          Silver
        </li>
        <li>
          Non-ECF members can pay a £2.50 Pay-to-Play fee or apply via the ECF
          web site for a free 1 year Silver membership provided they have not
          been an ECF member previously
        </li>
      </ul>

      <h2>Officials</h2>
      <p>
        We are delighted to announce that we will have at least one accredited
        coach in attendance, Lucas Piecha. He will observe games and provide
        training sessions between rounds. Details will be announced once they
        are confirmed. Lucas is a FIDE Schools Trainer.
      </p>

      <h2>Useful Internet Addresses</h2>
      <ul>
        <li>
          Chesshodd.com:{" "}
          <a href="https://chesshood.com/">https://chesshood.com/</a>
        </li>
        <li>
          Hampshire Junior Chess Association (HJCA):{" "}
          <a href="http://www.hjca.org.uk">www.hjca.org.uk</a>
        </li>
        <li>
          London Junior Chess Championships (LJCC):{" "}
          <a href="http://www.ljcc.co.uk">www.ljcc.co.uk</a>
        </li>
        <li>
          Hampshire Chess Association (HCA):{" "}
          <a href="http://www.hampshirechess.co.uk">www.hampshirechess.co.uk</a>
        </li>
        <li>
          English Chess Federation (ECF):{" "}
          <a href="http://www.englishchess.org.uk">www.englishchess.org.uk</a>
        </li>
        <li>
          ECF rating Database:{" "}
          <a href="http://www.ecfrating.org.uk">www.ecfrating.org.uk</a>
        </li>
        <li>
          British Chess News:{" "}
          <a href="http://www.facebook.com/groups/britishchess">
            www.facebook.com/groups/britishchess
          </a>
        </li>
        <li>
          British Chess News:{" "}
          <a href="http://www.britishchessnews.com">www.britishchessnews.com</a>
        </li>
        <li>
          Tim Onions Chess Supplies:{" "}
          <a href="http://www.onionschess.co.uk">onionschess.co.uk</a>
        </li>
      </ul>

      <p>
        For more information on the event and registration, visit{" "}
        <a href="http://www.hjca.org.uk">Hampshire Junior Chess Association</a>.
      </p>
    </div>
  );
};

export default ChessTournamentInfo;
