import React from "react";

// Spacer component that can be used to add vertical or horizontal space between components
const Spaciador = ({ axis, size }) => {
  const style = {
    ...(axis === "vertical" ? { height: size } : { width: size }),
    ...(axis === "horizontal" ? { display: "inline-block" } : {}),
  };

  return (
    <div style={style}>
      <br></br>
      <hr></hr>
    </div>
  );
};

export default Spaciador;
