import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import SideNav from "./components/SideNav";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Clubwjcc from "./pages/Clubwjcc";

import PrivateLessons from "./pages/PrivateLessons";
import OnlineMeetings from "./pages/OnlineMeetings";
import Chesscamp from "./pages/Chesscamp";
import ScheduleTable from "./pages/ScheduleTable";
import JuniorClubWinchester from "./pages/JuniorClubWinchester";
import ChessTournamentInfo from "./pages/ChessTournamentInfo";
import ContactPage from "./pages/ContactPage";
import LichessTeamBattleInfo from "./pages/LichessTeamBattleInfo";
import ChessActivitiesSouthamptonCentral from "./pages/ChessActivitiesSouthamptonCentral"; // Import the new component
import HJCABlitz from "./pages/HJCABlitz";
const App = () => {
  useEffect(() => {
    document.title = "Winchester Junior";
  }, []);
  return (
    <div>
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={2} lg={2} xl={2} xxl={2} className="pt-3">
            <SideNav />{" "}
          </Col>
          <Col xs={12} sm={12} md={10} lg={10} xl={10} xxl={10} className="p-4">
            <Routes>
              <Route path="/" element={<Clubwjcc />} />

              <Route path="/PrivateLessons" element={<PrivateLessons />} />
              <Route path="/OnlineMeetings" element={<OnlineMeetings />} />
              <Route
                path="/JuniorClubWinchester"
                element={<JuniorClubWinchester />}
              />
              <Route path="/ScheduleTable" element={<ScheduleTable />} />

              <Route path="/ContactPage" element={<ContactPage />} />
              {/* <Route path="/RegistrationForm" element={<RegistrationForm />} /> */}
              <Route path="/Chesscamp" element={<Chesscamp />} />
              <Route
                path="/ChessTournamentInfo"
                element={<ChessTournamentInfo />}
              />
              <Route
                path="/LichessTeamBattleInfo"
                element={<LichessTeamBattleInfo />}
              />
              <Route
                path="/ChessActivitiesSouthamptonCentral"
                element={<ChessActivitiesSouthamptonCentral />}
              />
              <Route path="/HJCABlitz" element={<HJCABlitz />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default App;
